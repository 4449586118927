<template>
  <div id="app">
    <Menu v-if="$store.getters.isAuthenticated" />
    <Page />
  </div>
</template>

<script>
import Menu from './components/menu.vue'
import Page from './components/page.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Page,
  },
}
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';

#app {
  display: flex;
}

.mx-datepicker {
  width: 100% !important;
}
</style>
