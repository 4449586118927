import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import TreeView from 'vue-json-tree-view'

import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import 'tinymce/skins/content/default/content.min.css'

// A theme is also required
import 'tinymce/themes/silver'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/lists'

Vue.use(BootstrapVue)
Vue.use(TreeView)

axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)
Vue.use(IconsPlugin)
Vue.use(tinymce)
Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
