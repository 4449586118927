import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { name: 'Video' } },
  {
    path: '/users',
    name: 'Users',
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: { auth: true, title: 'Пользователи', roles: [] },
  },
  {
    path: '/users/test-devices',
    name: 'TestDevices',
    component: () => import(/* webpackChunkName: "users/test-devices" */ '../views/TestDevices.vue'), meta: { auth: true, title: 'Test Devices', roles: [] },
  },
  {
    path: '/users/show/:id',
    name: 'UserShow',
    component: () =>
      import(/* webpackChunkName: "user" */ '@/views/UserShow.vue'),
    meta: { auth: true, title: 'Просмотр карточки пользователя', roles: [] },
  },
  {
    path: '/video',
    name: 'Video',
    component: () =>
      import(/* webpackChunkName: "video" */ '../views/Video.vue'),
    meta: { auth: true, title: 'Видео', roles: [] },
  },
  {
    path: '/files',
    name: 'Files',
    component: () =>
      import(/* webpackChunkName: "files" */ '../views/Files.vue'),
    meta: { auth: true, title: 'Файлы', roles: [] },
  },
  {
    path: '/promocodes',
    name: 'PromoCodes',
    component: () =>
      import(/* webpackChunkName: "promocodes" */ '../views/PromoCodes.vue'),
    meta: { auth: true, title: 'Промокоды', roles: [] },
  },
  {
    path: '/posts',
    name: 'Posts',
    component: () =>
      import(/* webpackChunkName: "posts" */ '../views/Posts.vue'),
    meta: { auth: true, title: 'Посты', roles: [] },
  },
  // {
  //   path: "/post/:id",
  //   name: "Post",
  //   component: () => import(/* webpackChunkName: "post" */ "../views/Post.vue"),
  //   meta: { auth: true, title: "Пост", roles: [] }
  // },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { auth: false, title: 'Вход', roles: [] },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
    meta: { auth: false, title: 'Вход', roles: [] },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// router.afterEach((to, from) => {
//   let msg = {
//     event: "onNavigate",
//     data: {
//       from: from.fullPath,
//       to: to.fullPath
//     }
//   };
//   parent.postMessage(msg, "*");
// });

router.beforeEach((to, from, next) => {
  //console.log(to)
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el),
  )

  let redirectPath = '/login'
  if (to.meta.auth && !store.getters.isAuthenticated) {
    // требуется авторизация, переадресуем на логин
    next({ path: redirectPath })
  }
  if (store.getters.isAuthenticated && store.getters.isAboutToExpire) {
    store.dispatch('reLogin')
  }
  let userRoles = []
  userRoles = store.getters.getUserRoles
  if (
    userRoles.length > 0 &&
    to.matched.some(
      (record) =>
        record.meta.roles.filter((value) => -1 !== userRoles.indexOf(value))
          .length === 0 && record.meta.roles.length > 0,
    )
  ) {
    // если роли в роуте и токене не совпадают то адресуем в первый роут для роли или в логин если вообще не найдено

    userRoles.forEach((role) => {
      router.options.routes.forEach((routeItem) => {
        if (routeItem.meta && routeItem.meta.roles.includes(role)) {
          //console.log(role, routeItem, routeItem.meta.roles.includes(role));
          if (redirectPath === '/login')
            redirectPath = routeItem.path.replace('*', '')
        }
      })
    })
    next({ path: redirectPath })
  } else {
    next()
  }
})
export default router
