<template>
  <div class="content">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'page',
}
</script>

<style lang="scss" scoped>
.content{
  padding: 25px;
  width: 100%;
}
</style>
