import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(Vuex)


export default new Vuex.Store({
  plugins: [createPersistedState({key: 'synergy-admin'})],
  state: {
    user: {
      token: '',
      username: '',
      roles: [],
      isAuthenticated: false,
      expire: '',
      password: '',
    },
    testHashes: [],
  },
  getters: {
    getTestHashes: (state) => {
      return state.testHashes
    },
    isAuthenticated: (state) => {
      return state.user.isAuthenticated
    },
    getUserRoles: (state) => {
      if (state.user.roles.length > 0)
        return state.user.roles
      if (state.user.token) {
        return VueJwtDecode.decode(state.user.token).roles
      }
      return []
    },
    isAboutToExpire: (state) => {
      try {
        let date = new Date((state.user.expire - (30 * 60)) * 1000) // продлевать за час до окончания
        console.debug('Exp', date)
        console.debug('Now', new Date())
        console.debug(date < (new Date()))
        return date < (new Date())
      } catch (e) {
        console.log(e)
        return false
      }
    },
    getAxiosConfig: (state) => {
      return {
        headers: {Authorization: 'Bearer ' + state.user.token},
      }
    },
  },
  mutations: {
    setTestHashes(state, payload) {
      state.testHashes = payload
    },
    toggleTestHash(state, payload) {
      payload.forEach(payloadItem => {
        const hashIndex = state.testHashes.indexOf(payloadItem)
        if (hashIndex != -1) {
          this.dispatch('queryDeleteTestHash', hashIndex)
        } else {
          this.dispatch('queryAddTestHash', payloadItem)
        }
      })
    },
    addTestHash(state, payload) {
      state.testHashes.push(payload)
    },
    rmTestHash(state, payload) {
      state.testHashes.splice(payload, 1)
    },
    login(state, payload) {
      state.user.token = payload.access_token
      state.user.password = payload.password
      state.user.isAuthenticated = true
      const tokenContent = VueJwtDecode.decode(state.user.token)

      state.user.roles = tokenContent.roles
      state.user.username = tokenContent.username
      state.user.expire = tokenContent.exp
    },
    logout(state) {
      state.user.token = ''
      state.user.isAuthenticated = false
      state.user.roles = []
      state.user.username = ''
    },
  },
  actions: {
    async queryTestDevices(context) {
      const resp = await Vue.axios.get(process.env.VUE_APP_BACKEND_URL + '/admin/api/test-devices', this.getters.getAxiosConfig)

      try {
        if (resp.error)
          throw (new Error('error'))
        context.commit('setTestHashes', resp.data.testDevices.map(item => item.hash))
      } catch (error) {
        console.log(error)
      }
    },
    async queryAddTestHash(context, payload) {
      const resp = await Vue.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/test-devices/' + payload, null, this.getters.getAxiosConfig)

      try {
        if (resp.error)
          throw (new Error('error'))
        context.commit('addTestHash', payload)
      } catch (error) {
        console.log(error)
      }
    },
    async queryDeleteTestHash(context, payload) {
      const resp = await Vue.axios.delete(process.env.VUE_APP_BACKEND_URL + '/admin/api/test-devices/' + context.state.testHashes[payload], this.getters.getAxiosConfig)

      try {
        if (resp.error)
          throw (new Error('error'))
        context.commit('rmTestHash', payload)
      } catch (error) {
        console.log(error)
      }
    },
    reLogin(context) {
      if (context.state.user.password && context.state.user.username && context.state.user.password.length > 2 && context.state.user.username.length > 2) {
        Vue.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/user/login', {
          username: context.state.user.username,
          password: context.state.user.password,
        })
          .then((response) => {
            this.busyButton = false
            if (response.data && !response.data.error) {
              context.commit('login', {
                access_token: response.data.access_token,
                password: context.state.user.password,
              })
            }
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },
  },
  modules: {},
})
