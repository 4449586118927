<template>
  <div class="menu">
    <nav>
      <router-link
        :to="{ name: 'Users' }"
        class="nav-item"
      >
        Пользователи
      </router-link>
      <router-link v-if="$route.name == 'Users' || $route.name == 'TestDevices'" :to="{ name: 'TestDevices'}" class="nav-item text-right mt-0">
        ТестХеши
      </router-link>

      <router-link :to="{ name: 'Video' }" class="nav-item">
        Видео
      </router-link>
      <router-link :to="{ name: 'Files' }" class="nav-item">
        Файлы
      </router-link>
      <router-link :to="{ name: 'PromoCodes' }" class="nav-item">
        Промокоды
      </router-link>
      <!-- <router-link :to="{ name: 'Posts' }" class="nav-item">
        Посты
      </router-link> -->
    </nav>
  </div>
</template>
<script>
export default {
  name: 'Menu',
}
</script>

<style lang="scss" scoped>
.menu {
  min-height: 100vh;
  width: 165px;
  padding: 25px;
  border-right: 1px solid #ccc;
  position: sticky;
  background-color: #f5f5f5;

  a {
    color: #333;
  }

  a:hover {
    color: #666;
    text-decoration: none;
  }
}

nav {
  .nav-item {
    margin: 10px 0;
    display: block;
  }

  .router-link-active {
    font-weight: bold;
  }
}
</style>
